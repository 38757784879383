import * as React from 'react';

const MaskSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="mask_svg__eds-icon--mask_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="mask_svg__eds-icon--mask_base"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.1 8.6v1l3.2.7-3.4 7.7c-.8 1.8-2.6 3-4.6 3-.4 0-.8 0-1.1-.1-.5.1-1.1.2-1.7.2H9.2l.6.3c.8.4 1.6.5 2.5.5 2.3 0 4.5-1.3 5.5-3.6l4-8.8-4.7-.9z"
        />
        <path
            id="mask_svg__eds-icon--mask_mask"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.1 13.2V2l-7 1.5L2.2 2v11.2c0 3.4 2.4 6.2 5.6 6.8.4.1.9.1 1.4.1h.3c3.7-.1 6.6-3.2 6.6-6.9zm-6.9 5.9c-1.3 0-1.8-.3-2.3-.4-2.2-.9-3.7-3-3.7-5.5v-10l6 1.3 6-1.3v9.9c-.1 3.4-2.7 6-6 6"
        />
        <path
            id="mask_svg__eds-icon--mask_mouth"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.9 13.2c-1.5 1.5-3.9 1.5-5.4 0l-.4-.4-.7.7.4.4c.9.9 2.2 1.4 3.4 1.4 1.2 0 2.5-.5 3.4-1.4l.4-.4-.7-.7-.4.4z"
        />
        <path
            id="mask_svg__eds-icon--mask_eye_1_"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.2 8.3c-.3.3-.8.3-1.1 0l-.3-.3-.7.7.4.4c.3.3.8.5 1.2.5.4 0 .9-.2 1.2-.5l.4-.4-.7-.7-.4.3z"
        />
        <path
            id="mask_svg__eds-icon--mask_eye"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.5 9.2l.4-.4-.8-.6-.3.3c-.3.3-.8.3-1.1 0l-.3-.3-.7.7.3.3c.3.3.8.5 1.2.5s.9-.1 1.3-.5z"
        />
    </svg>
);

MaskSvg.displayName = 'MaskSvg';
export default MaskSvg;
