import * as React from 'react';

const ParkSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="park_svg__eds-icon--park_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="park_svg__eds-icon--park_base"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22 18.5h-3V17h2v-4H10.7c.5-1.1.5-2.3 0-3.6L6.5 2 2.4 9.5c-1.3 2.8.7 6 3.7 6.3V22h1v-6.3c.7-.1 1.4-.3 2-.7v2h2v1.5H8v1h1V22h1v-2.5h10V22h1v-2.5h1v-1zM7 14.7V10H6v2l-1.4-1.4-.7.7L6 13.4v1.3c-1-.1-1.9-.7-2.5-1.6-.7-1-.8-2.2-.3-3.3L6.4 4l3.2 5.8c.5 1.1.4 2.3-.3 3.3-.4.9-1.3 1.5-2.3 1.6zm3-.6l.1-.1H20v2H10v-1.9zm2 4.4h6V17h-6v1.5z"
        />
    </svg>
);

ParkSvg.displayName = 'ParkSvg';
export default ParkSvg;
