import * as React from 'react';

const TravelSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="travel_svg__eds-icon--travel_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="travel_svg__eds-icon--travel_base"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#231F20"
            d="M12.5 10.4l1-1 1 1V13h-2v-2.6zM4 21h2V6H4v15zm11.5-7v-4L14 8.5V6h3v15H7V6h6v2.4L11.5 10v4h4zm2.5 7h2V6h-2v15zM9 5h6V3H9v2zM8 2v3H3v17h18V5h-5V2H8z"
        />
    </svg>
);

TravelSvg.displayName = 'TravelSvg';
export default TravelSvg;
