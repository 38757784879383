import * as React from 'react';

const BeakerSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        className="beaker_svg__eds-icon--beaker_svg"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            className="beaker_svg__eds-icon--beaker_base"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14 7.3V4h1V3H9v1h1v3.3c-2.9.9-5 3.5-5 6.7 0 3.9 3.1 7 7 7s7-3.1 7-7c0-3.2-2.1-5.8-5-6.7zM12 20c-2.7 0-5-1.8-5.8-4.3h11.5c-.7 2.5-3 4.3-5.7 4.3zm-6-5.4V14v-.4h2v-1.1H6.2c.1-.4.2-.7.4-1.1h1.5v-1.1h-.9c.8-.9 1.8-1.7 3.1-2L11 8V4h2v4l.7.2C16.2 9 18 11.4 18 14v.6H6z"
        />
    </svg>
);

BeakerSvg.displayName = 'BeakerSvg';
export default BeakerSvg;
