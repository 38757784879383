import * as React from 'react';

const CharitySvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="charity_svg__eds-icon--charity_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="charity_svg__eds-icon--charity_base"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#231F20"
            d="M5.8 21.9l-.7-.7 2.4-2.6c3.1-3.3 7.9-8.3 9.3-9.9 1-1.1 1.4-2.3 1.1-3.6-.4-1.5-1.8-2.2-3-2.2-1.1 0-2.1.6-2.5 1.5l-.4.7-.4-.6c-1-1.5-2.8-1.9-4.3-1.1C6.5 4 6 4.9 5.9 5.8c0 1.1.3 2 1.2 2.9l3.8 4-.7.7-3.8-4C5.4 8.3 4.9 7.2 5 5.8c.1-1.3.8-2.5 1.9-3.2 1.7-1 3.7-.6 5.1.8.7-.9 1.8-1.4 3-1.4 1.9 0 3.5 1.2 3.9 2.9.4 1.6 0 3.2-1.3 4.5-1.6 1.6-6.3 6.6-9.4 10l-2.4 2.5z"
        />
        <path
            id="charity_svg__eds-icon--charity_line"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#231F20"
            d="M18.3 22l-5.1-5.4.6-.7 5.1 5.4z"
        />
    </svg>
);

CharitySvg.displayName = 'CharitySvg';
export default CharitySvg;
