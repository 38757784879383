import * as React from 'react';

const FashionSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="fashion_svg__eds-icon--fashion_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="fashion_svg__eds-icon--fashion_base"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#231F20"
            d="M24 15.1l-11.5-4.3v-.9c1.1-.2 2-1.2 2-2.4C14.5 6.1 13.4 5 12 5S9.5 6.1 9.5 7.5h1c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5S12.8 9 12 9h-.5v1.8L0 15.1l.3.9 1.7-.6V18h5v1h1v-1h8v1h1v-1h5v-2.6l1.7.6.3-.9zM21 17h-4v-1h-1v1H8v-1H7v1H3v-1.9l9-3.3 9 3.3V17z"
        />
    </svg>
);

FashionSvg.displayName = 'FashionSvg';
export default FashionSvg;
