import * as React from 'react';

const FilmCameraSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="film-camera_svg__eds-icon--film-camera_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="film-camera_svg__eds-icon--film-camera_base"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22 12.5l-5 1.7v-2.3c1.7-.4 3-2 3-3.9 0-2.2-1.7-3.9-3.9-4H5.8C3.7 4.1 2 5.9 2 8c0 1.9 1.3 3.4 3 3.9V20h12v-3.2l5 1.7v-6zM19 8c0 1.7-1.3 3-3 3s-3-1.3-3-3 1.3-3 3-3c1.6 0 3 1.3 3 3zm-5.6-3c-.9.7-1.4 1.8-1.4 3s.5 2.3 1.4 3H8.6c.9-.7 1.4-1.8 1.4-3s-.5-2.3-1.4-3h4.8zM3 8c0-1.7 1.3-3 3-3s3 1.3 3 3-1.3 3-3 3-3-1.3-3-3zm13 11H6v-7h10v7zm5-1.9l-4-1.3v-.6l4-1.3v3.2z"
        />
    </svg>
);

FilmCameraSvg.displayName = 'FilmCameraSvg';
export default FilmCameraSvg;
