import {
    Arts as ArtsSvg,
    Auto as AutoSvg,
    Beaker as BeakerSvg,
    Book as BookSvg,
    Briefcase as BriefcaseSvg,
    BuisnessProfession as BuisnessProfessionSvg,
    Calendar as CalendarSvg,
    Charity as CharitySvg,
    Cocktail as CocktailSvg,
    Dating as DatingSvg,
    DiscoBall as DiscoBallSvg,
    Fashion as FashionSvg,
    FilmCamera as FilmCameraSvg,
    FilmMedia,
    FoodDrink as FoodDrinkSvg,
    Game as GameSvg,
    Gear as GearSvg,
    Health as HealthSvg,
    Heart as HeartSvg,
    Hobbies as HobbiesSvg,
    Holiday as HolidaySvg,
    HolidayCalendar as HolidayCalendarSvg,
    House as HouseSvg,
    LinkChunky as LinkChunkySvg,
    LogoFacebook as LogoFacebookSvg,
    LogoGooglePlus as LogoGooglePlusSvg,
    LogoInstagram as LogoInstagramSvg,
    LogoLinkedin as LogoLinkedinSvg,
    LogoPinterest as LogoPinterestSvg,
    LogoTwitter as LogoTwitterSvg,
    Mask as MaskSvg,
    Megaphone as MegaphoneSvg,
    MusicNote as MusicNoteSvg,
    Park as ParkSvg,
    Religion as ReligionSvg,
    RockingHorse as RockingHorseSvg,
    Shoe as ShoeSvg,
    Sports as SportsSvg,
    Star as StarSvg,
    Travel as TravelSvg,
} from '@eventbrite/eds-iconography';

import {} from '@eventbrite/eds-iconography';
import { gettext } from '@eventbrite/i18n';
import classNames from 'classnames';
import React from 'react';
import { DecorativeImagePlacement } from '../types/Media';

import {
    ACTIVE_SEASONAL_EVENTBRITE_CATEGORY,
    AUTO_BOAT_AIR,
    AUTO_BOAT_AIR_EVENTBRITE_CATEGORY,
    BUSINESS,
    BUSINESS_EVENTBRITE_CATEGORY,
    CHARITY,
    CHARITY_EVENTBRITE_CATEGORY,
    COMMUNITY,
    COMMUNITY_EVENTBRITE_CATEGORY,
    DATING_EVENTBRITE_SUB_CATEGORY,
    FAMILY_EDUCATION,
    FAMILY_EDUCATION_EVENTBRITE_CATEGORY,
    FASHION,
    FASHION_EVENTBRITE_CATEGORY,
    FESTIVAL_EVENTBRITE_FORMAT,
    FILM,
    FILM_EVENTBRITE_CATEGORY,
    FOOD,
    FOOD_AND_DRINK_EVENTBRITE_CATEGORY,
    HEALTH,
    HEALTH_EVENTBRITE_CATEGORY,
    HOBBIES,
    HOBBIES_EVENTBRITE_CATEGORY,
    HOME_LIFESTYLE,
    HOME_LIFESTYLE_EVENTBRITE_CATEGORY,
    MUSIC,
    MUSIC_EVENTBRITE_CATEGORY,
    NIGHTLIFE,
    NIGHTLIFE_EVENTBRITE_CATEGORY,
    OTHER,
    OTHER_EVENTBRITE_CATEGORY,
    PERFORMING,
    PERFORMING_EVENTBRITE_CATEGORY,
    POLITICS,
    POLITICS_EVENTBRITE_CATEGORY,
    RELIGION,
    RELIGION_EVENTBRITE_CATEGORY,
    SCHOOL,
    SCHOOL_EVENTBRITE_CATEGORY,
    SCIENCE,
    SCIENCE_EVENTBRITE_CATEGORY,
    SEASONAL,
    SEASONAL_EVENTBRITE_CATEGORY,
    SPORTS,
    SPORTS_EVENTBRITE_CATEGORY,
    TRAVEL,
    TRAVEL_EVENTBRITE_CATEGORY,
} from '@eventbrite/categories';
import { EVENT_TYPE } from '@eventbrite/discover-utils';
import {
    END_DATE,
    EVENT_PAGE_PARAM,
    START_DATE,
} from '@eventbrite/search-utils';
import { BucketContentType, BucketEntityType } from '../types';
export { FESTIVAL_EVENTBRITE_FORMAT };

export const GOOGLE_MAPS_CLIENT_KEY = 'gme-eventbrite';

export const EVENTBRITE = 'Eventbrite';
export const TICKETFLY = 'Ticketfly';
export const GOLDSTAR = 'Goldstar';

export const LINK_FOLLOW_ALLOWLIST = [EVENTBRITE, TICKETFLY];

/****************************** TEMPLATES AND CARD TYPES ********************************/

export const EVENT_CARD_TYPE = EVENT_TYPE;
export const ARTICLE_CARD_TYPE = 'article';
export const PROFILE_CARD_TYPE = 'profile';
export const CATEGORY_TYPE = 'category';
export const NEARBY_CITY_TYPE = 'place';
export const TRENDING_SEARCH_TYPE = 'trending';
export const COLLECTION_TYPE = 'collection';
export const EVENT_TIME_OPTIONS_TYPE = 'event_time_options';

export const ENTITY_TYPE_MAP: Record<BucketEntityType, BucketContentType> = {
    [EVENT_CARD_TYPE]: 'events',
    [ARTICLE_CARD_TYPE]: 'articles',
    [CATEGORY_TYPE]: 'tags',
    [NEARBY_CITY_TYPE]: 'places',
    [TRENDING_SEARCH_TYPE]: 'trending',
    [COLLECTION_TYPE]: 'collections',
    [EVENT_TIME_OPTIONS_TYPE]: 'event_time_options',
};

export const SINGULARIZED_ENTITY_TYPE_MAP: { [index: string]: string } = {
    events: EVENT_CARD_TYPE,
    promoted_results: EVENT_CARD_TYPE,
    top_match: EVENT_CARD_TYPE,
    profiles: PROFILE_CARD_TYPE,
    articles: ARTICLE_CARD_TYPE,
    places: NEARBY_CITY_TYPE,
};

/****************************** COPY ********************************/

export const DEFAULT_BLURB_NO_BUCKETS = gettext(
    "Looks like we don't have any current events listed nearby. Try searching another city.",
);
export const DEFAULT_BLURB_NO_EVENTS = gettext(
    "Looks like we don't have any current events listed, let's see what's going on in nearby cities.",
);
export const getDefaultBlurb = (city: string) =>
    gettext(
        "Check out the latest events in %(city)s. Whether you're a local, new to the area, or cruising through, there's no shortage of unique things to do.",
        { city },
    );

export const EVENT_SAVE = gettext('Event has been saved successfully.');
export const EVENT_SAVE_ERROR = gettext(
    'There was a problem saving the event, please try again.',
);

/****************************** City Browse Location Constants ********************************/

export const CITY_CUTOFF = 6;
export const CITY_NAME_SHORT_CUT = 2;
export const CITY_NAME_DEFAULT_CUT = 4;
export const CITY_GRADIENTS = ['eds-bg-color--ui-orange'];
export const SHORT_STATES = {
    alabama: 'AL',
    arizona: 'AZ',
    arkansas: 'AR',
    california: 'CA',
    colorado: 'CO',
    connecticut: 'CT',
    delaware: 'DE',
    'district of columbia': 'DC',
    florida: 'FL',
    georgia: 'GA',
    idaho: 'ID',
    illinois: 'IL',
    indiana: 'IN',
    iowa: 'IA',
    kansas: 'KS',
    kentucky: 'KY',
    louisiana: 'LA',
    maine: 'ME',
    maryland: 'MD',
    massachusetts: 'MA',
    michigan: 'MI',
    minnesota: 'MN',
    mississippi: 'MS',
    missouri: 'MO',
    montana: 'MT',
    nebraska: 'NE',
    nevada: 'NV',
    'new hampshire': 'NH',
    'new jersey': 'NJ',
    'new mexico': 'NM',
    'new york': 'NY',
    'north carolina': 'NC',
    'north dakota': 'ND',
    ohio: 'OH',
    oklahoma: 'OK',
    oregon: 'OR',
    pennsylvania: 'PA',
    'rhode island': 'RI',
    'south carolina': 'SC',
    'south dakota': 'SD',
    tennessee: 'TN',
    texas: 'TX',
    utah: 'UT',
    vermont: 'VT',
    virginia: 'VA',
    washington: 'WA',
    'west virginia': 'WV',
    wisconsin: 'WI',
    wyoming: 'WY',
};

/****************************** COOKIES CONSTANTS ********************************/

export const EVENT_COOKIE = 'ebEventId';

/****************************** GA ******************************/

export const GA_USER_CURRENT_LOCATION_ACTION = 'UseCurrentLocation';
export const GA_USER_CURRENT_LOCATION_DENIED_LABEL = 'access denied';
export const GA_USER_CURRENT_LOCATION_ERROR_LABEL = 'location error';

//  This constants are used to track the different type of suggestions listed on the global header
export const SUGGESTION_TYPE_HISTORY = 'recent';
export const SUGGESTION_TYPE_POPULAR = 'popular';

/****************************** Current Location ******************************/

export const LOCATION_DIALOG_CONTENT = {
    headerIconType: <GearSvg />,
    title: gettext('Location Access Blocked'),
    message: gettext(
        'We are unable to resolve your current location. Please check your browser and system permissions and try again.',
    ),
    primaryText: gettext('Okay'),
    primaryType: 'link',
};

export const CATEGORY_LONG_NAME_ICON_MAP = {
    [BUSINESS_EVENTBRITE_CATEGORY]: (
        <BuisnessProfessionSvg viewBox="0 0 32 33" />
    ),
    [MUSIC_EVENTBRITE_CATEGORY]: <MusicNoteSvg />,
    [NIGHTLIFE_EVENTBRITE_CATEGORY]: (
        <DiscoBallSvg {...{ viewBox: '0 0 47 48' }} />
    ),
    [PERFORMING_EVENTBRITE_CATEGORY]: <ArtsSvg viewBox="0 0 40 41" />,
    [SCIENCE_EVENTBRITE_CATEGORY]: <BeakerSvg />,
    [COMMUNITY_EVENTBRITE_CATEGORY]: <ParkSvg />,
    [FOOD_AND_DRINK_EVENTBRITE_CATEGORY]: <FoodDrinkSvg viewBox="0 0 40 41" />,
    [FILM_EVENTBRITE_CATEGORY]: <FilmMedia viewBox="0 0 32 32" />,
    [SPORTS_EVENTBRITE_CATEGORY]: <SportsSvg viewBox="0 0 32 32" />,
    [HEALTH_EVENTBRITE_CATEGORY]: <HealthSvg viewBox="0 0 32 32" />,
    [TRAVEL_EVENTBRITE_CATEGORY]: <TravelSvg />,
    [CHARITY_EVENTBRITE_CATEGORY]: <CharitySvg />,
    [RELIGION_EVENTBRITE_CATEGORY]: <ReligionSvg />,
    [FAMILY_EDUCATION_EVENTBRITE_CATEGORY]: <RockingHorseSvg />,
    [SEASONAL_EVENTBRITE_CATEGORY]: <HolidayCalendarSvg viewBox="0 0 28 36" />,
    [SCHOOL_EVENTBRITE_CATEGORY]: <BookSvg />,
    [ACTIVE_SEASONAL_EVENTBRITE_CATEGORY]: <CalendarSvg />,
    [DATING_EVENTBRITE_SUB_CATEGORY]: <DatingSvg viewBox="0 0 43 42" />,
    //Backup: 'trash'
    [POLITICS_EVENTBRITE_CATEGORY]: <MegaphoneSvg />,
    [FASHION_EVENTBRITE_CATEGORY]: <FashionSvg />,
    [HOME_LIFESTYLE_EVENTBRITE_CATEGORY]: <HouseSvg />,
    [AUTO_BOAT_AIR_EVENTBRITE_CATEGORY]: <AutoSvg />,
    [HOBBIES_EVENTBRITE_CATEGORY]: <HobbiesSvg viewBox="0 0 32 32" />,
    [OTHER_EVENTBRITE_CATEGORY]: <StarSvg />,
};

export const MOBILE_BREAKPOINT = '822px';

/****************************** SHARED CLASSES ********************************/

export const SHARED_BUCKET_SPACING_CLASSES = classNames(
    'content-bucket',
    'eds-l-pad-vert-16',
);

export const NON_EVENT_BUCKET_SPACING_CLASSES = classNames(
    'content-bucket',
    'eds-l-pad-top-16',
    'eds-l-sw-pad-vert-16',
    'eds-l-sm-pad-vert-16',
    'eds-l-sn-pad-vert-16',
);

export const BUCKET_WRAPPER_WITH_DECORATION_CLASSES = classNames(
    'eds-l-mar-top-5',
    'eds-l-sm-mar-top-16',
    'eds-l-sn-mar-top-16',
);

export const TAG_BACKGROUND_COLOR = 'eds-bg-color--ui-blue';
export const TAG_STYLE = 'outlined';

/****************************** TEMPLATES AND CARD TYPES ********************************/

export const ARTICLE_TEMPLATE = 'article';
export const CALENDAR_TEMPLATE = 'calendar';
export const FEATURED_TEMPLATE = 'featured';
export const CATEGORY_TEMPLATE = 'category';
export const MAP_TEMPLATE = 'map';
export const NEARBY_CITIES_TEMPLATE = 'nearby_place';
export const TRENDING_SEARCHES_TEMPLATE = 'trending_searches';
export const COLLECTION_TEMPLATE = 'collection';
export const ORGANIZER_FOLLOW_TEMPLATE = 'organizer_follow';
export const EVENT_TIME_OPTIONS_TEMPLATE = 'event_time_options';

export const LARGE_CARD_TEMPLATE_3 = 'card_big_3';
export const LARGE_CARD_TEMPLATE_6 = 'card_big_6';
export const LIST_CARD_TEMPLATE = 'card_list';
export const CARD_TEMPLATE_4 = 'card_small_4';
export const CARD_TEMPLATE_8 = 'card_small_8';
export const CARD_BIG = 'card_big';

const THIS_WEEKEND_BUCKETS_KEY = 'this_weekend';
const POPULAR_EVENTS_BUCKET_KEY = 'popular_events';
const CURATED_EVENTS_BUCKET_KEY = 'best_of_city_events';
const MUSIC_EVENTS_BUCKET_KEY = 'music_events';

export const EDITORIAL_ARTICLES_BUCKET_KEY = 'editorial_articles';
export const FREE_EVENTS_BUCKET_KEY = 'free_events';

/****************************** URLS ********************************/

export const BASE_URL = '/d';
export const BROWSE_URL = ':location/events/';
export const SEARCH_URL = ':location/:refinementOne(/:refinementTwo)/';

export const SEARCH_RELEVANT_QUERY_PARAMS = [
    START_DATE,
    END_DATE,
    EVENT_PAGE_PARAM,
    'src',
    'bbox',
    'subcategories',
    'cur',
    'lang',
    'hash',
    'followed_orgs',
    'organization',
];

/****************************** CATEGORY ID MAPPING ********************************/

//Very TBD, will be replaced once official
//category graphics are put together.
export const CATEGORY_NAME_ICON_MAP: { [key: number]: React.ReactNode } = {
    [BUSINESS]: <BriefcaseSvg />,
    [MUSIC]: <MusicNoteSvg />,
    [NIGHTLIFE]: <DiscoBallSvg />,
    [PERFORMING]: <MaskSvg />,
    [SCIENCE]: <BeakerSvg />,
    [COMMUNITY]: <ParkSvg />,
    [FOOD]: <CocktailSvg />,
    [FILM]: <FilmCameraSvg />,
    [SPORTS]: <ShoeSvg />,
    [HEALTH]: <HeartSvg />,
    [TRAVEL]: <TravelSvg />,
    [CHARITY]: <CharitySvg />,
    [RELIGION]: <ReligionSvg />,
    [FAMILY_EDUCATION]: <RockingHorseSvg />,
    [SEASONAL]: <HolidaySvg />,
    [SCHOOL]: <BookSvg />,
    //Backup: 'trash'
    [POLITICS]: <MegaphoneSvg />,
    [FASHION]: <FashionSvg />,
    [HOME_LIFESTYLE]: <HouseSvg />,
    [AUTO_BOAT_AIR]: <AutoSvg />,
    [HOBBIES]: <GameSvg />,
    [OTHER]: <StarSvg />,
};

export const CATEGORY_DROPDOWN_SELECT = 'category_select';

/****************************** IMAGES ********************************/

export const DECORATIVE_IMAGE_PLACEMENT_MAP: DecorativeImagePlacement[] = [
    {
        selectors: [POPULAR_EVENTS_BUCKET_KEY, CURATED_EVENTS_BUCKET_KEY],
        urlSelector: 'decorationTopRightUrl',
        position: 'right',
        classes: 'decorative-image--one',
        parentClasses: 'eds-l-pad-top-6',
    },
    {
        selectors: [THIS_WEEKEND_BUCKETS_KEY],
        urlSelector: 'decorationTopLeftUrl',
        position: 'left',
        classes: 'decorative-image--two',
        parentClasses: 'eds-l-pad-top-12',
    },
    {
        selectors: [EDITORIAL_ARTICLES_BUCKET_KEY, MUSIC_EVENTS_BUCKET_KEY],
        urlSelector: 'decorationBottomRightUrl',
        position: 'right',
        classes: 'decorative-image--three',
        parentClasses: 'eds-l-pad-top-6',
    },
    {
        selectors: [FREE_EVENTS_BUCKET_KEY],
        urlSelector: 'decorationBottomLeftUrl',
        position: 'left',
        classes: 'decorative-image--four',
        parentClasses: 'eds-l-pad-top-6',
    },
];

/****************************** Artist Social Link Tye Constants ********************************/

export const SOCIAL_TYPE_ICON_MAP: { [key: string]: React.ReactElement } = {
    instagram: <LogoInstagramSvg />,
    twitter: <LogoTwitterSvg />,
    linkedin: <LogoLinkedinSvg />,
    facebook: <LogoFacebookSvg />,
    pinterest: <LogoPinterestSvg />,
    'google-plus': <LogoGooglePlusSvg />,
    web: <LinkChunkySvg />,
};

export const HEADER_HEIGHT = 72;

export const DEFAULT_DESTINATION_EVENT_EXPANSIONS = [
    'primary_venue',
    'image',
    'ticket_availability',
    'saves',
    'event_sales_status',
    'primary_organizer',
    'public_collections',
];

export const CHATBOT_GEO_LOCATIONS = [
    '85936429', // ATLANTA
    '85950361', // BOSTON_MA
    '85940195', // CHICAGO
    '101724385', // DALLAS
    '101725629', // HOUSTON
    '85923517', // LOS_ANGELES
    '85933669', // MIAMI
    '85977539', // NEW_YORK
    '85922583', // SAN_FRANCISCO
    '85931779', // WASHINGTON_DC
];
