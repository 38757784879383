import * as React from 'react';

const LogoInstagramSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 24 24" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.5 6.7c0-.7-.5-1.2-1.2-1.2s-1.2.5-1.2 1.2.5 1.2 1.2 1.2 1.2-.6 1.2-1.2zM12 15.3c-1.8 0-3.3-1.5-3.3-3.3 0-1.8 1.5-3.3 3.3-3.3 1.8 0 3.3 1.5 3.3 3.3 0 1.8-1.5 3.3-3.3 3.3zm0-8.4c-2.8 0-5.1 2.3-5.1 5.1s2.3 5.1 5.1 5.1 5.1-2.3 5.1-5.1-2.3-5.1-5.1-5.1zM12 2c2.7 0 3.1 0 4.1.1 1.1 0 1.8.2 2.4.5.7.3 1.2.6 1.8 1.2.6.6.9 1.1 1.2 1.8.2.6.4 1.4.5 2.4v4c0 2.7 0 3.1-.1 4.1 0 1.1-.2 1.8-.5 2.4-.3.7-.6 1.2-1.2 1.8-.6.6-1.1.9-1.8 1.2-.6.2-1.4.4-2.4.5h-4c-2.7 0-3.1 0-4.1-.1-1.1 0-1.8-.2-2.4-.5-.7-.3-1.2-.6-1.8-1.2-.6-.6-.9-1.1-1.2-1.8-.2-.6-.4-1.4-.5-2.4v-4c0-2.7 0-3.1.1-4.1 0-1.1.2-1.8.5-2.4.3-.7.6-1.2 1.2-1.8.6-.6 1.1-.9 1.8-1.2C6.2 2.3 7 2.1 8 2h4zm0 1.8c2.7 0 3 0 4 .1 1 0 1.5.2 1.9.3.5.2.8.4 1.1.8.3.3.6.7.7 1.1.2.4.4.9.4 1.9 0 1.1.1 1.4.1 4s0 3-.1 4c0 1-.2 1.5-.3 1.9-.2.5-.4.8-.7 1.1-.3.3-.7.6-1.1.7-.4.1-.9.3-1.9.3-1.1 0-1.4.1-4 .1-2.7 0-3 0-4-.1-1 0-1.5-.2-1.9-.3-.6-.1-.9-.3-1.2-.7-.3-.3-.6-.7-.7-1.1-.2-.4-.4-.9-.4-1.9 0-1.1-.1-1.4-.1-4s0-3 .1-4c0-1 .2-1.5.3-1.9.2-.5.4-.8.8-1.1.3-.3.7-.6 1.1-.7.4-.2.9-.4 1.9-.4 1-.1 1.3-.1 4-.1"
        />
    </svg>
);

LogoInstagramSvg.displayName = 'LogoInstagramSvg';
export default LogoInstagramSvg;
