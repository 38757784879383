import * as React from 'react';

const BookSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        className="book_svg__eds-icon--book_svg"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            className="book_svg__eds-icon--book_base"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 2H4v20h15v-3h1V2zm-2 19H5.2c.4-1.2 1.5-2 2.8-2h10v2zm1-3H8c-1.2 0-2.3.5-3 1.4V3h14v15z"
        />
        <path
            className="book_svg__eds-icon--book_line_2_"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 13h8v1H8z"
        />
        <path
            className="book_svg__eds-icon--book_line"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 10h8v1H8z"
        />
        <path
            className="book_svg__eds-icon--book_line_1_"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 7h8v1H8z"
        />
    </svg>
);

BookSvg.displayName = 'BookSvg';
export default BookSvg;
