import * as React from 'react';

const GameSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="game_svg__eds-icon--game_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="game_svg__eds-icon--game_base"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11 8h2V7h-2v1zm10 11H3V9h18v10zM14 8V6h-1.5V4h-1v2H10v2H2v12h20V8h-8z"
        />
        <path
            id="game_svg__eds-icon--game_button"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 12c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 3c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z"
        />
        <path
            id="game_svg__eds-icon--game_pad"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 12H7v1.5H5.5v1H7V16h1v-1.5h1.5v-1H8z"
        />
    </svg>
);

GameSvg.displayName = 'GameSvg';
export default GameSvg;
