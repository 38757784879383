import * as React from 'react';

const LogoLinkedinSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 24 24" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.3 21.5h4.3V8.7H2.3v12.8zm2.2-19C3 2.5 2 3.5 2 4.7 2 6 2.9 7 4.5 7 6 7 6.9 6 6.9 4.7c0-1.2-1-2.2-2.4-2.2zm12.8 5.9c-2.2 0-3.1.7-4.1 2.1V8.7H8.9v12.8h4.3v-7c0-.3 0-.7.1-1 .3-1 1.1-1.7 2.2-1.7s1.7.6 2 1.5c.2.7.2 1.6.2 2.1v6.1H22v-7.6c0-2.5-1.2-5.5-4.7-5.5z"
        />
    </svg>
);

LogoLinkedinSvg.displayName = 'LogoLinkedinSvg';
export default LogoLinkedinSvg;
