import * as React from 'react';

const BriefcaseSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        className="briefcase_svg__eds-icon--briefcase_svg"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            className="briefcase_svg__eds-icon--briefcase_base"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17 14v-2h2v7H5v-7h2v2h1v-2h8v2h1zM4 7h16v4h-3v-1h-1v1H8v-1H7v1H4V7zm5-1h6V5H9v1zM8 4v2H3v6h1v8h16v-8h1V6h-5V4H8z"
        />
    </svg>
);

BriefcaseSvg.displayName = 'BriefcaseSvg';
export default BriefcaseSvg;
