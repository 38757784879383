import * as React from 'react';

const ReligionSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="religion_svg__eds-icon--politics_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="religion_svg__eds-icon--religion_base"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.8 13.9l-.2-.6-.6-.2L5.8 12l4.3-1.2.6-.1.2-.6L12 5.8l1.2 4.3.1.6.6.1 4.3 1.2-4.3 1.2-.6.2-.1.6-1.2 4.2-1.2-4.3zM12 2L9.9 9.9 2 12l7.9 2.1L12 22l2.1-7.9L22 12l-7.9-2.1L12 2z"
        />
        <g
            id="religion_svg__eds-icon--religion_dashes"
            fillRule="evenodd"
            clipRule="evenodd"
        >
            <path
                transform="rotate(-45.001 6.64 17.312)"
                d="M5.8 16.9h1.7v.8H5.8z"
            />
            <path
                transform="rotate(-44.988 17.31 17.36)"
                d="M16.9 16.5h.8v1.7h-.8z"
            />
            <path
                transform="rotate(-45.001 17.336 6.664)"
                d="M16.5 6.2h1.7V7h-1.7z"
            />
            <path
                transform="rotate(-44.988 6.64 6.688)"
                d="M6.2 5.9H7v1.7h-.8z"
            />
        </g>
    </svg>
);

ReligionSvg.displayName = 'ReligionSvg';
export default ReligionSvg;
