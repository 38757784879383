import * as React from 'react';

const LogoFacebookSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 22 22" {...props}>
        <path d="M14.893 11.89L15.336 9h-2.773V7.124c0-.79.387-1.562 1.63-1.562h1.26v-2.46s-1.144-.196-2.238-.196c-2.285 0-3.777 1.385-3.777 3.89V9h-2.54v2.89h2.54v6.989a10.075 10.075 0 003.124 0V11.89h2.33" />
    </svg>
);

LogoFacebookSvg.displayName = 'LogoFacebookSvg';
export default LogoFacebookSvg;
