import * as React from 'react';

const RockingHorseSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="rocking-horse_svg__eds-icon--rocking-horse_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="rocking-horse_svg__eds-icon--rocking-horse_base"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21 17.3l-.7.7L18 9.1c0-.2 0-.4.1-.5.2-.2.6-.3.8-.1l.2.2c.6.5 1.6.4 2.1-.1s.6-1.4.2-2l-3-4.5-1.4 2c-2.1.1-3.8 1.9-3.8 4.1 0 .2 0 .5.1.8H4.1v1H6l-2.1 8c-.3-.2-.6-.5-.8-.8l-.8.8c2.7 2.7 6.2 4 9.7 4 3.5 0 7-1.3 9.7-4l-.7-.7zM17.3 5.1h.3l.9-1.3L20.6 7c.2.2.1.6-.1.8-.2.2-.6.2-.8 0l-.2-.2c-.3-.2-.6-.4-1-.4-.5 0-.9.2-1.2.6-.3.4-.4.9-.3 1.4l1.9 7.2c-2.2-2.2-4.6-2.7-6.3-2.7h-.4v-.8c1.4-.3 2.5-1.6 2.5-3l-.2-.7c-.1-.3-.2-.8-.2-1.2-.1-1.5 1.3-2.9 3-2.9zM13.6 10c0 1.2-.9 2.1-2.1 2.1-1.2 0-2.1-1-2.1-2.1h4.2zm-8.2 6.2L7 10h1.3c0 1.6 1.2 2.9 2.8 3.1v.8c-2.6.4-4.6 1.7-5.8 2.8l.1-.5zm-.7 2.5c.5-.7 3-3.6 7.1-3.9h.7c2.7 0 5 1.3 6.8 3.8l.1.1c-4.4 3.1-10.3 3.1-14.7 0z"
        />
    </svg>
);

RockingHorseSvg.displayName = 'RockingHorseSvg';
export default RockingHorseSvg;
