import * as React from 'react';

const CalendarSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="calendar_svg__eds-icon--calendar_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="calendar_svg__eds-icon--calendar_base"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17 4V2h-1v2H8V2H7v2H2v18h20V4h-5zm4 17H3V9h18v12zM3 8V5h4v1h1V5h8v1h1V5h4v3H3z"
        />
        <g
            id="calendar_svg__eds-icon--calendar_squares"
            fillRule="evenodd"
            clipRule="evenodd"
        >
            <path d="M15 16h2v2h-2zM11 16h2v2h-2zM7 16h2v2H7zM15 12h2v2h-2zM11 12h2v2h-2zM7 12h2v2H7z" />
        </g>
    </svg>
);

CalendarSvg.displayName = 'CalendarSvg';
export default CalendarSvg;
