import * as React from 'react';

const AutoSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        className="auto_svg__eds-icon--auto_svg"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            className="auto_svg__eds-icon--auto_base"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#231F20"
            d="M22 10h-3V4H5v6H2v1h1.3c-.2.3-.3.6-.3 1v6h1v1c0 1.1.9 2 2 2s2-.9 2-2v-1h8v1c0 1.1.9 2 2 2s2-.9 2-2v-1h1v-6c0-.4-.1-.7-.3-1H22v-1zM6 5h12v5h-5.5V7h-1v3H6V5zm1 14c0 .6-.4 1-1 1s-1-.4-1-1v-1h2v1zm12 0c0 .6-.4 1-1 1s-1-.4-1-1v-1h2v1zm1-2H4v-5c0-.6.4-1 .9-1h14c.6 0 1.1.4 1.1 1v5z"
        />
        <g
            className="auto_svg__eds-icon--auto_circles"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#231F20"
        >
            <path d="M17 12c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 3c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1c0 .5-.4 1-1 1zM7 12c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 3c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1c0 .5-.5 1-1 1z" />
        </g>
        <path
            className="auto_svg__eds-icon--auto_dash"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#231F20"
            d="M11 13h2v1h-2z"
        />
    </svg>
);

AutoSvg.displayName = 'AutoSvg';
export default AutoSvg;
