import * as React from 'react';

const MegaphoneSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="megaphone_svg__eds-icon--megaphone_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="megaphone_svg__eds-icon--megaphone_base"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#231F20"
            d="M21 12c0-1.7-1.5-3.1-3-3.1V3h-1v1.1L7 9H3v6h2v3h1v-3h1.2l9.8 4.8V21h1v-5.9c1.5 0 3-1.4 3-3.1zM4 14h3v-4H4v4zm13 4.7l-9-4.4V9.6l9-4.4v13.5zm1-4.6V9.9c1 0 2 1 2 2.1 0 1.2-1 2.1-2 2.1z"
        />
    </svg>
);

MegaphoneSvg.displayName = 'MegaphoneSvg';
export default MegaphoneSvg;
