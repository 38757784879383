import * as React from 'react';

const LinkChunkySvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="link-chunky_svg__eds-icon--link-chunky_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="link-chunky_svg__eds-icon--link-chunky_right"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 15.5V7h-7v2h5v6h-5v2h7z"
        />
        <path
            id="link-chunky_svg__eds-icon--link-chunky_base"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9 11h6v2H9z"
        />
        <path
            id="link-chunky_svg__eds-icon--link-chunky_left"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 15.5V7h7v2H6v6h5v2H4v-1.5z"
        />
    </svg>
);

LinkChunkySvg.displayName = 'LinkChunkySvg';
export default LinkChunkySvg;
