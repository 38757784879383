import * as React from 'react';

const HouseSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        className="house_svg__eds-icon--house__svg"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            className="house_svg__eds-icon--house__base"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 9.9V22h6v-6h4v6h6V9.9l1.3 1 .7-.8L12 2 2 10.2l.6.8L4 9.9zm15-.8V21h-4v-6H9v6H5V9.1l7-5.6 7 5.6z"
        />
    </svg>
);

HouseSvg.displayName = 'HouseSvg';
export default HouseSvg;
