import * as React from 'react';

const HolidaySvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="holiday_svg__eds-icon--holiday_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="holiday_svg__eds-icon--holiday_base"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#231F20"
            d="M7 2v16.4h14V2H7zm13 15.4H8v-1.1l4.4-4.9 2.8 3.2 1.4-1.5 3.3 3.4v.9zm0-2.4l-3.3-3.4-1.4 1.4-2.8-3.2-4.5 5V3h12v12z"
        />
        <path
            id="holiday_svg__eds-icon--holiday_corner"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#231F20"
            d="M5.8 4.1l-1-.1L3 20.3l1 .1 12.9 1.5.1-1-12.9-1.5L5.8 4.1z"
        />
        <path
            id="holiday_svg__eds-icon--holiday_circle"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#231F20"
            d="M16.6 9.3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0-3.1c.6 0 1.1.5 1.1 1.1 0 .6-.5 1.1-1.1 1.1s-1.1-.5-1.1-1.1c0-.6.5-1.1 1.1-1.1z"
        />
    </svg>
);

HolidaySvg.displayName = 'HolidaySvg';
export default HolidaySvg;
