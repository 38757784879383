import * as React from 'react';

const ShoeSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="shoe_svg__eds-icon--sport_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="shoe_svg__eds-icon--sport_base"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#231F20"
            d="M22 17c0-6-6-8-6-8l-6-2S8.5 9 6 9C4.7 9 3.1 8 3.1 8H2v9h2v1h1v-1h2v1h1v-1h8v1h1v-1h2v1h1v-1h2zM3 16v-2h3c1.7 0 2.6.6 2.9 2H3zm6.9 0c-.4-2.4-2.1-3-3.9-3H3V9.1c.7.3 1.9.9 3 .9 2.1 0 3.6-1.1 4.3-1.8l.7.2V13h1V8.7l1 .3v4h1V9.4l1 .3V13h1v-2.9c1.1.4 4.4 2.1 4.9 5.9h-11z"
        />
    </svg>
);

ShoeSvg.displayName = 'ShoeSvg';
export default ShoeSvg;
