import * as React from 'react';

const LogoPinterestSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 24 24" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.1 11.4S7.6 17 7.5 18.8c-.1 1.9.1 3.2.1 3.2s1.1-.8 1.8-2.4c.8-1.8 1.2-4.4 1.2-4.4s1.4 1.4 3.2 1.4c2.1 0 5.9-1.9 5.9-7.8C19.7 3 14.1 2 12.2 2 7.7 2 4.3 5.2 4.3 9.4c0 1.5.4 2.5 1 3.2.8.8 1.8 1 1.8 1s.4-.5.4-1.3-1-.9-1-3c0-3.1 2.7-5.5 5.8-5.5 2.9 0 4.6 1.9 4.6 4.8 0 1.7-.5 5.9-3.7 5.9-1.6 0-1.8-1-1.8-1.6 0-1.3 1.1-3.5 1.1-5 0-1.3-1.2-1.5-1.6-1.5-1.4 0-2.2 1.8-2.2 3.3 0 1.1.4 1.7.4 1.7"
        />
    </svg>
);

LogoPinterestSvg.displayName = 'LogoPinterestSvg';
export default LogoPinterestSvg;
