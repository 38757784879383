import * as React from 'react';

const LogoGooglePlusSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 24 24" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.4 8.9h-2v2h-2v2h2v2h2v-2h2v-2h-2v-2zM9.5 5c-3.9 0-7 3.1-7 7s3.1 7 7 7c4 0 6.7-2.9 6.7-6.8 0-.5 0-.8-.1-1.2H9.5v2.4h4c-.2 1-1.2 3-4 3-2.4 0-4.3-2-4.3-4.4s1.9-4.4 4.3-4.4c1.4 0 2.3.6 2.8 1.1l1.9-1.8C13 5.7 11.4 5 9.5 5z"
        />
    </svg>
);

LogoGooglePlusSvg.displayName = 'LogoGooglePlusSvg';
export default LogoGooglePlusSvg;
