import * as React from 'react';

const GearSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="gear_svg__eds-icon--gear_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="gear_svg__eds-icon--gear_base"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 3v2l.8.2c.6.2 1.2.4 1.7.8l.7.4.5-.5.9-.9L19 6.4l-.9.9-.5.5.4.7c.3.6.6 1.2.7 1.8l.3.7h2v2h-2l-.2.8c-.2.6-.4 1.2-.7 1.8l-.4.7.5.5.9.9-1.4 1.4-.9-.9-.5-.5-.8.3c-.6.3-1.2.6-1.8.7l-.7.3v2h-2v-2l-.8-.2c-.6-.2-1.2-.4-1.7-.8l-.7-.4-.5.5-.9.9L5 17.6l.9-.9.5-.5-.4-.7c-.3-.6-.6-1.2-.7-1.8L5 13H3v-2h2l.2-.8c.2-.6.4-1.2.8-1.7l.4-.7-.6-.6-.9-.9 1.4-1.4.9.9.5.5.8-.3c.5-.4 1.1-.6 1.7-.8L11 5V3h2zm1-1h-4v2.3c-.7.2-1.4.4-2.1.8L6.3 3.5 3.5 6.3l1.6 1.6c-.4.7-.6 1.4-.8 2.1H2v4h2.3c.2.7.5 1.4.9 2.1l-1.6 1.6 2.8 2.8L8 18.9c.6.4 1.3.7 2.1.9V22h4v-2.3c.7-.2 1.4-.5 2.1-.9l1.6 1.6 2.8-2.8L19 16c.4-.6.7-1.3.9-2.1H22v-4h-2.3c-.2-.7-.5-1.4-.9-2.1l1.6-1.6-2.8-2.8L16 5c-.6-.4-1.3-.7-2.1-.9V2z"
        />
        <path
            id="gear_svg__eds-icon--gear_circle"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 8c-2.2 0-4 1.8-4 4s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4zm0 7c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3z"
        />
    </svg>
);

GearSvg.displayName = 'GearSvg';
export default GearSvg;
